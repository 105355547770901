.container {
  @apply absolute bg-blue-onahau flex justify-center items-center cursor-pointer pointer-events-none opacity-0;
  bottom: 45px;
  right: 40px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: opacity 0.15s linear;

  &Visible {
    @apply pointer-events-auto opacity-100;
  }
}
