@import 'styles/_variables.scss';

.filters {
  @apply relative w-full z-10;
  max-width: 160px;
  border-radius: 15px;
}

.openButton {
  @apply w-full;
}

.buttonInner {
  @apply flex justify-between relative;
  padding: 10px;

  &:after {
    @apply block absolute bottom-2 bg-orange;
    width: 95px;
    content: '';
    height: 2px;
    left: 10px;
  }
}

.openButtonText {
  @apply text-left flex-grow;
  font-weight: 700;
}

.arrow {
  transform: rotate(-90deg);
  transition: transform 0.15s linear;
}

.list {
  @apply absolute top-0 w-full hidden overflow-hidden;
  z-index: -1;
  list-style: none;
  border-radius: 15px;
  padding-top: 44px;
}

.listItem {
  @apply bg-white cursor-pointer text-base uppercase;

  @screen lg {
    font-size: 18px;
  }

  &:hover {
    @apply bg-gray-light;
  }

  &Active {
    @apply bg-gray-light;
  }
}

.listItemButton {
  @apply w-full text-left;
  padding: 10px;
}

.filtersOpen {
  .arrow {
    transform: rotate(90deg);
  }

  .list {
    @apply block;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
  }
}
