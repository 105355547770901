.container {
  @apply section-size mx-auto grid justify-between;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;

  @screen xl {
    grid-template-columns: repeat(2, 1fr);
  }
}

.columnLeft {
  @screen xl {
    @apply mr-5;
  }
}

.heading {
  @apply mb-10 uppercase;
}

.linkNotRemember {
  @apply block text-right mt-2 mb-10 ml-auto uppercase text-base;
  font-weight: 900;
}

.registerLink {
  font-weight: 700;
}

.figure {
  @apply w-fit;
  position: relative;
  padding-top: 18px;
  padding-right: 22px;

  &::after {
    @apply absolute bg-blue-light -z-10 hidden xl:block;
    content: '';
    width: 93%;
    height: 87%;
    top: 0;
    right: 0;
    border-radius: 10px;
  }
}

.image {
  @apply mx-auto self-center hidden xl:block;
  max-width: 300px;

  @screen lg {
    max-width: 446px;
  }
}
