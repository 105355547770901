@import 'styles/_variables.scss';

.link {
  @apply flex justify-start items-center rotate-90 uppercase;
  width: fit-content;

  @screen lg {
    font-size: 18px;
  }
}

.arrowBack {
  width: 7px;
  height: 7px;
  margin-right: 5px;
  margin-bottom: 2px;
  border: 1px solid $color-blue-pickled;
  border-bottom: 0;
  border-right: 0;
  transform: rotate(-45deg);
}
