@import 'styles/_variables.scss';

.layout {
  @apply flex flex-col h-full;

  @screen lg {
    @apply flex-row;
  }
}

.menu {
  @apply w-full p-10 px-5 pb-0 z-40 relative;

  @screen lg {
    @apply p-10 px-5 w-auto;
    min-width: 340px;
    max-width: 340px;
    box-shadow: 20px 20px 74px rgba(0, 0, 0, 0.25);
  }

  @screen xl {
    @apply p-10;
    min-width: 384px;
    max-width: 384px;
  }
}

.line {
  @apply block w-full absolute left-0;
  content: '';
  height: 1px;
  border-bottom: 1px solid $color-blue-light;
}

.container {
  @apply bg-blue-water grow;
}

.content {
  @apply elearning-section h-full mx-auto;
}

.box {
  @apply h-full px-5 py-10 relative bg-white flex flex-col;

  @screen lg {
    @apply p-10 h-auto;
    box-shadow: 20px 20px 74px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
  }
}
