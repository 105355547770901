@layer utilities {
  .section-size {
    max-width: 1440px;
    padding: 40px 20px;
    @screen md {
      padding: 60px 90px;
    }
    @screen lg {
      padding: 80px 155px;
    }
  }

  .bg-transparent {
    background-color: transparent;
  }

  .elearning-section {
    max-width: 1440px;
    padding: 0;

    @screen lg {
      padding: 40px 60px 80px 60px;
    }

    @screen xl {
      padding: 40px 154px 80px 154px;
    }
  }

  .divider {
    @apply my-10 w-full relative;
    height: 1px;
    &::before {
      @apply absolute bg-blue-light h-full top-0 -left-10;
      width: calc(100% + 80px);
      content: '';
    }
  }
}
