@import 'styles/_variables.scss';

.line {
  @apply block w-full absolute left-0;
  content: '';
  height: 1px;
  border-bottom: 1px solid $color-blue-light;
}

.wrapper {
  @apply flex flex-col items-center pt-10;

  @screen lg {
    @apply flex-row justify-start;
  }
}

.iconWrapper {
  @apply mb-5 mb-0;

  @screen lg {
    @apply mb-0 mr-10;
  }
}

.infoWrapper {
  @apply flex flex-col items-center text-center;

  @screen lg {
    @apply items-start text-left;
  }
}

.heading {
  margin-bottom: 10px;
}

.paragraph {
  @apply mb-5 text-center lg:text-left;
}

.containerStandalone {
  .line {
    @apply hidden;
  }

  .wrapper {
    @apply pt-0;
  }
}
