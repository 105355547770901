@import 'styles/variables';

.inputWrapper {
  @apply text-left;
}

.input {
  @apply text-lg w-full text-blue-pickled bg-white;
  background-color: white;
  height: 200px;
  line-height: 1.5;
  padding: 7px;
  border: 1px solid $color-blue-light;
  border-radius: 4px;

  &:focus {
    @apply outline-blue;
  }
}

.label {
  @apply block text-lg text-blue-pickled mb-1;
}

.errorBox {
  @apply text-sm text-red;
}

.inputWrapperError {
  .input {
    border: 1px solid $color-red;
  }
}
