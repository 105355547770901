.container {
  @apply flex flex-col items-start;
  @screen sm {
    @apply flex-row;
  }
}

.checkbox {
  @apply appearance-none relative top-0 left-0 w-0 h-0;
  margin-top: 4px;
  margin-right: 10px;
  &::after {
    @apply absolute z-10 top-0 left-0 border-gray border-solid;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    content: '';
    border-width: 3px;
    transform: translateY(-20px);
  }

  &:checked {
    &::before {
      @apply absolute z-10 bg-blue border-blue-light border-solid;
      content: '';
      top: -13px;
      left: 7px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
  }
}

.checkboxLabel {
  @apply block text-left;
}

.text {
  @apply relative font-bold mr-10 mt-5;
  font-size: 16px;
  line-height: 1.5;
  @screen sm {
    @apply mt-0;
    font-size: 18px;
  }
  padding-top: 3px;
}

.errorBox {
  @apply text-sm text-red text-left;
  margin-top: 5px;
}

.checkboxError {
  &::after {
    @apply border-red border-solid;
  }
}
