.container {
  @apply section-size mx-auto flex;
}

.header {
  @screen lg {
    margin-bottom: 25px;
  }
}

.left {
  @apply flex-1 flex flex-col;
  @screen lg {
    margin-right: 10px;
  }
}

.right {
  @apply flex-1 hidden;

  @screen lg {
    @apply block;
    margin-left: 10px;
  }
}

.videoMobile {
  @apply my-5;
  @screen lg {
    @apply hidden;
  }
}
