.accordion {
  @apply overflow-y-auto h-0;
  transition: height 0.15s;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #808080;
    border: 0;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background: #eee9e9;
    border: 0;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.label {
  @apply mb-5 flex font-bold items-center;
}

.arrow {
  @apply ml-3;
  transition: transform 0.15s;
}

.reversed {
  transform: rotate(180deg);
}
