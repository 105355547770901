@import 'typography';
@import 'variables';
@import 'mixins';
@import 'utils';
@import 'carousel';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-blue-pickled;
  font-family: $font-poppins;
}
