@import 'styles/_variables.scss';

.button {
  @apply text-base flex items-center;
  background-color: $color-orange;
  color: white;
  height: 46px;
  border-radius: 50px;
  font-weight: 700;
  padding: 11px 20px;
  white-space: nowrap;

  &:disabled {
    @apply text-blue-dirty bg-gray-light;
  }
}

.buttonSecondary {
  @apply bg-blue-dirty;
}

.buttonTertiary {
  @apply bg-transparent border-blue-dirty border-solid text-blue-dirty;
  border-width: 1px;
}

.buttonInverted {
  @apply bg-white text-orange;
}
