.container {
  @apply section-size mx-auto flex flex-col flex-wrap;
  @screen xl {
    @apply flex-row;
  }
}

.header {
  @apply mb-7 uppercase;
}

.text {
  @apply mb-7;
  font-size: 16px;
  line-height: 1.5;
  @screen lg {
    font-size: 18px;
  }
}

.content {
  @apply flex-1;
}

.image {
  @apply flex-1 items-center hidden xl:flex ml-8;
}

.imageTablet {
  @apply flex-1 flex items-center xl:hidden mb-5;
}
