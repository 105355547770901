.container {
  @apply section-size mx-auto;
}

.heading {
  @apply text-center mb-6;

  @screen lg {
    margin-bottom: 60px;
  }
}

.backButton {
  @apply mb-8;

  @screen lg {
    margin-bottom: 60px;
  }
}

.divider {
  @apply w-full bg-gray-light my-5;
  height: 1px;

  @screen md {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

.summary {
  @apply bg-blue-light flex flex-col;
  padding: 20px 8px;

  @screen md {
    @apply flex-row justify-between;
    padding: 30px 48px 30px 40px;
  }
}

.info {
  @apply mb-8;

  @screen md {
    @apply mb-0;
  }
}

.totalCostText {
  font-size: 30px;
  font-weight: 700;
}

.sendButton {
  @apply mx-auto;

  @screen md {
    @apply mx-0 place-self-center;
  }
}

.pagination {
  @apply mb-5;

  @screen md {
    margin-top: -20px;
  }
}
