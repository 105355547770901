@import 'styles/_variables.scss';

.item {
  @apply text-base;
  border-bottom: 1px solid $color-blue-light;

  &:last-child {
    border: 0;
  }

  @screen lg {
    @apply text-lg;
  }
}

.itemLink {
  @apply flex justify-between items-center py-5;
  border-bottom: 1px solid $color-blue-light;

  &:last-of-type {
    border: 0;
  }

  &Active {
    font-weight: 700;
  }

  &Blocked {
    // pointer-events: none;
    // opacity: 0.5;
  }
}

.itemStatus {
  @apply w-9 h-9 mr-4 flex justify-center items-center shrink-0;
  border-radius: 50%;
  border: 1px solid $color-blue-light;

  &Done {
    @apply bg-blue;
  }

  &Green {
    @apply bg-green;
  }
}

.itemTopicWrapper {
  @apply flex justify-start items-center mr-1;
}

.itemArrow {
  @apply shrink-0;
}
