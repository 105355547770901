.navigationContainer {
  @apply fixed top-0 left-0 w-full bg-white;
  padding: 13px 16px 13px 10px;
  box-shadow: 0px -5px 15px 0px #9e9ea1;
  z-index: 60;

  @screen md {
    padding: 10px 20px 10px 20px;
  }
}

.navigationContent {
  @apply mx-auto flex justify-between items-center;
  max-width: 1132px;
}

.navigation {
  @apply hidden;

  @screen lg {
    @apply flex justify-between;
  }
}

.navigationItemsMobile {
  @apply fixed flex flex-col p-5 items-center w-full bg-white z-50;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  top: 72px;
  left: 0;
  transform: translateY(-150%);

  @screen md {
    top: 90px;
  }
  @screen lg {
    @apply hidden;
  }
}

.isMenuOpen {
  transform: translateY(0);
  transition: transform 0.35s;
}

.navigationItem {
  @apply text-lg uppercase flex items-center text-center;
  line-height: 1.5;
  padding: 0 15px;
  &:not(&:last-of-type) {
    padding: 0 15px 15px;
  }

  @screen lg {
    @apply text-sm p-0 mr-4;
    line-height: 1.5;
    &:not(&:last-of-type) {
      padding: 0;
    }
  }
  @screen xl {
    @apply mr-0;
    &::after {
      content: '';
      @apply bg-blue mx-4 hidden;
      width: 7px;
      height: 7px;
      min-width: 7px;
      min-height: 7px;
      border-radius: 50%;
      @screen lg {
        @apply inline-block;
      }
    }
  }

  &:last-of-type::after {
    content: none;
  }
}

.link {
  @apply ml-4;

  @screen xl {
    @apply ml-12 text-base;
  }
}

.logo {
  @apply mr-4 w-full;
  max-width: 80px;
  height: auto;

  @screen md {
    @apply flex items-center;
    max-width: none;
    width: 147px;
    height: 70px;
  }
}

.navigationMobile {
  @apply flex justify-items-end;

  @screen lg {
    @apply hidden;
  }
}

.burgerWrapper {
  max-height: 46px;
  @screen lg {
    @apply hidden;
  }
}

.linkMobile {
  @apply ml-2 text-xs;

  @screen lg {
    @apply hidden;
  }
}

.navigationLogin {
  @apply hidden;
  @screen lg {
    @apply block;
  }
}
