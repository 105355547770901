.link {
  @apply ml-2 text-xs;

  @screen lg {
    @apply ml-4;
  }

  @screen xl {
    @apply ml-12 text-base;
  }
}
