@import 'styles/_variables.scss';

.wrapper {
  @apply mb-10;
}

.answer {
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid $color-blue-light;

  &Checked {
    @apply bg-blue-light text-blue;
    border: 1px solid transparent;
  }

  &Correct {
    @apply bg-green/10 text-green;
    border: 1px solid transparent;
  }

  &Error {
    @apply bg-red/10 text-red;
    border: 1px solid transparent;
  }
}

.answerText {
  @apply text-left;
  padding: 10px 20px;
}

.headingWrapper {
  @apply flex justify-start items-center mb-5;
}

.bullet {
  @apply text-white w-9 h-9 bg-blue flex justify-center items-center;
  min-width: 36px;
  margin-right: 10px;
  border-radius: 50%;
  font-weight: 700;

  @screen lg {
    @apply mr-5;
  }
}

.button {
  @apply w-full;
}
