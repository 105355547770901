@import 'styles/_variables.scss';

.item {
  @apply text-base bg-blue px-5 text-white;
  border-radius: 15px;
  box-shadow: 20px 20px 74px rgba(0, 0, 0, 0.25);

  @screen lg {
    @apply px-10;
  }

  &Done {
    @apply bg-green;
  }

  &Blocked {
    // pointer-events: none;
    // opacity: 0.5;
  }
}

.itemLink {
  @apply flex justify-between items-center py-5;
}

.itemStatus {
  @apply w-9 h-9 mr-4 flex justify-center items-center shrink-0;
  border-radius: 50%;
  border: 1px solid $color-blue-light;

  &Done {
    @apply bg-white;
  }
}

.itemTopicWrapper {
  @apply flex justify-start items-center mr-1 text-lg;
}

.itemArrow {
  @apply shrink-0;
}
