@import 'styles/_variables.scss';

.container {
  @apply flex flex-col overflow-hidden;
  border: 1px solid $color-blue-light;
  border-radius: 10px;
}

.dashedBoxWrapper {
  @apply h-full;
  padding: 5px;
}

.dashedBox {
  @apply bg-blue-water h-full;
  border: 2px dashed white;
  border-radius: 10px;
}

.dropzone {
  @apply bg-blue-water;
  height: 93px;

  @screen lg {
    height: 181px;
  }

  &WithImg {
    background-color: transparent;
  }

  &Over {
    @apply bg-blue opacity-50;

    .dashedBox {
      @apply bg-blue;
    }
  }
}

.description {
  @apply flex-grow;
  padding: 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  border-top: 1px solid $color-blue-light;
}
