@font-face {
  font-family: 'Poppins';
  src: url('./../assets/fonts/Poppins-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url('./../assets/fonts/Poppins-Light.ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url('./../assets/fonts/Poppins-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src: url('./../assets/fonts/Poppins-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Poppins';
  src: url('./../assets/fonts/Poppins-Black.ttf');
  font-weight: 900;
}
