.wrapper {
  @apply bg-blue-light;
}

.container {
  @apply section-size mx-auto text-center;
}

.header {
  @apply mb-5;
}

.desc {
  @apply mb-5;
  @screen lg {
    @apply mb-10;
  }
}
