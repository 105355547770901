.container {
  @apply flex flex-col justify-center items-center mx-auto section-size;
  @screen md {
    @apply flex-row;
  }
}

.text {
  @apply w-full relative;
  @screen lg {
    @apply w-1/2;
    padding-top: 14.25%;
  }
  padding-top: 28.5%;

  video {
    @apply absolute top-0 left-0;
  }
}

.logo {
  @apply w-full relative;
  @screen lg {
    @apply w-1/2;
    padding-top: 38.6%;
  }
  padding-top: 77.3%;

  video {
    @apply absolute top-0 left-0;
  }
}
