$border-color: #f1f1f1;

.continer {
  @apply relative z-10;
}

.buttonText {
  @apply mr-3 text-xs;

  @screen xl {
    @apply mr-4 text-base;
  }
}

.arrowIcon {
  @apply w-3;
  transition: transform 0.15s linear;

  &Rotate {
    transform: rotate(180deg);
  }

  @screen xl {
    @apply w-4;
  }
}

.list {
  @apply absolute hidden bg-white text-center -z-10 pt-8 w-full;
  top: 23px;
  box-shadow: 0px 0px 10px 0px #d0d0d1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid $border-color;

  &Open {
    @apply block;
  }
}

.listItem {
  font-size: 13px;
  line-height: 1.5;

  & > * {
    text-transform: uppercase;
  }

  &:last-of-type {
    border-top: 1px solid $border-color;
    // padding: 15px 10px 12px;
  }

  &:hover {
    background-color: $border-color;
    cursor: pointer;
  }
}

.listItemPadding {
  @apply w-full block;
  padding: 10px;
}
