.container {
  @apply section-size mx-auto text-left lg:text-center;
}

.heading {
  @apply mb-10;
}

.form {
  @apply text-center lg:text-left;
}

.formFields {
  @apply grid;
  grid-template-columns: 1fr;

  @screen lg {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 20px;
  }
}
.paragraph {
  @apply mb-5 lg:mb-10;
}

.checkbox {
  @apply text-left mb-5;
}

.button {
  @apply my-5 mx-auto lg:my-10;
}

.accordion {
  @apply text-left;
}
