.wrapper {
  @apply bg-blue-light;
}

.container {
  @apply section-size mx-auto;
}

.box {
  @apply p-5 pb-0 mb-5 relative;

  @screen lg {
    @apply mb-10 px-10;
  }
}
