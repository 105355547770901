.modal {
  @apply fixed w-full h-full top-0 left-0 flex justify-center items-center;
  z-index: 100;
  padding: 90px 20px;
}

.bg {
  @apply w-full h-full absolute top-0 left-0 cursor-pointer;
  background-color: rgba(0, 0, 0, 0.75);
}

.wrapper {
  @apply bg-white h-full overflow-y-auto relative;
  width: 750px;
  padding: 71px 0px 51px;
  border-radius: 25px;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #808080;
    border: 0;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track {
    background: #eee9e9;
    border: 0;
    border-radius: 50px;
    margin: 25px 0;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.close {
  @apply absolute cursor-pointer hidden;
  width: 21px;
  height: 21px;
  top: 40px;
  right: 34px;
  @screen md {
    @apply block;
  }
}
