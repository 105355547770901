.list {
  @apply flex mt-14 text-lg;
  line-height: 1.5;
  @screen lg {
    min-width: 60px;
    @apply ml-5 mt-0 text-sm;
  }
}

.listItem {
  @apply flex items-center;
  &:not(&:last-of-type) {
    &::after {
      @apply mx-1 text-blue-dirty;
      content: '|';
    }
  }
}

.btn {
  @apply uppercase text-blue-dirty;
}

.active {
  @apply text-blue font-bold;
}
