$color-orange: #ff931e;
$color-blue: #007fc7;
$color-blue-light: #e4f2f9;
$color-blue-dark: #00446b;
$color-blue-dirty: #9bbac0;
$color-blue-pickled: #2e4a5a;
$color-blue-water: #e2f0f7;
$color-blue-onahau: #d1f0ff;
$color-gray-light: #f2f2f2;
$color-red: #e00034;
$color-green: #58a618;

$font-poppins: 'Poppins', sans-serif;
