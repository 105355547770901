.container {
  @apply section-size mx-auto flex items-center;
}

.header {
  @apply uppercase mb-10;
}

.content {
  @apply flex-1;
  @screen lg {
    margin-right: 30px;
  }
}

.image {
  @apply flex-1 relative hidden h-fit;
  @screen md {
    @apply block;
    margin-left: 30px;
  }

  &::after {
    @apply absolute bg-blue-light -z-10;
    content: '';
    width: 88%;
    height: 82%;
    top: 0;
    right: 0;
    border-radius: 10px;
  }

  img {
    padding-left: 15px;
    padding-bottom: 12px;
    @screen md {
      padding-right: 18px;
      padding-top: 22px;
    }
  }
}

.btn {
  @apply uppercase w-fit mx-auto mt-10;
}
