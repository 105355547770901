.tooltip {
  @apply border-blue-light border-solid bg-white z-10 block;
  border-width: 1px;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-height: 94px;
  &.visible {
    @apply block;
  }
  @screen lg {
    @apply fixed hidden;
    transform: translateX(-50%);
  }
}
