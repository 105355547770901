@import 'styles/_variables.scss';

.wrapper {
  @apply overflow-hidden;
  border: 1px solid $color-blue-light;
  border-radius: 10px;
}

.emptyBox {
  @apply bg-blue-water h-full;
  min-height: 93px;

  @screen lg {
    min-height: 183px;
  }
}
