.container {
  @apply section-size mx-auto;
}

.header {
  @apply uppercase text-center mb-5;
}

.desc {
  @apply text-center;
}

.topSection {
  @apply flex flex-col mt-9;
  @screen lg {
    @apply flex-row;
  }
}

.topSectionText {
  @apply flex-1;
  @screen lg {
    @apply mr-4;
  }
}

.topSectionImage {
  @apply flex-1 relative h-fit w-fit mx-auto;
  @screen lg {
    @apply ml-4;
  }

  &::after {
    @apply absolute bg-blue-light -z-10;
    content: '';
    width: 93%;
    height: 87%;
    bottom: 0;
    left: 0;
    border-radius: 10px;
  }

  img {
    padding-left: 15px;
    padding-bottom: 12px;
    @screen lg {
      padding-left: 22px;
      padding-bottom: 27px;
    }
  }
}

.topHeader {
  @apply uppercase;
  @screen lg {
    @apply mb-3;
  }
}

.topText,
.bottomText {
  @apply mb-5;
  @screen lg {
    @apply mb-8;
  }
}

.btn {
  @apply uppercase mb-5 w-fit;
}

.bottomSection {
  @apply flex flex-col-reverse my-12;
  @screen lg {
    @apply flex-row mt-16;
  }
}

.bottomSectionText {
  @apply flex-1;
  @screen lg {
    @apply ml-4;
  }
}

.bottomSectionImage {
  @apply flex-1 relative h-fit w-fit mx-auto;
  @screen lg {
    @apply mr-4;
  }

  &::after {
    @apply absolute bg-blue-light -z-10;
    content: '';
    width: 93%;
    height: 87%;
    bottom: 0;
    right: 0;
    border-radius: 10px;
  }

  img {
    padding-right: 12px;
    padding-bottom: 12px;
    @screen lg {
      padding-right: 20px;
      padding-bottom: 20px;
    }
  }
}
