@import 'styles/_variables.scss';

.product {
  @apply flex flex-col justify-between;

  &Image {
    @apply w-full mb-5;
    padding: 5px 10px;
    object-position: center;
    object-fit: contain;
    height: 220px;
    border: 1px solid $color-gray-light;

    @screen lg {
      height: 350px;
    }
  }

  .productInfo {
    @apply flex flex-col justify-between flex-1;
  }

  &Type {
    font-weight: 700;
    text-transform: uppercase;

    @screen lg {
      font-size: 13px;
    }
  }

  &Name {
    margin-bottom: 20px;

    @screen lg {
      font-size: 18px;
    }
  }

  &Group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .inputWrapper {
    @apply flex justify-between;
  }

  &Input {
    width: 50px;
    padding: 10px;
    border: 2px solid $color-gray-light;
    border-radius: 50px;
    font-size: 18px;
    text-align: center;
    margin-right: 5px;

    @media (min-width: 360px) {
      width: 60px;
    }

    @screen md {
      width: 70px;
      margin-right: 10px;
    }

    @screen lg {
      width: 80px;
    }
  }

  &Button {
    @apply w-full uppercase;
    justify-content: center;
    text-transform: uppercase;
  }
}
