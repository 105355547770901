.container {
  @apply section-size mx-auto;
}

.header {
  @apply uppercase text-center mb-8;
}

.desc {
  @apply text-center mb-8;
}

.cards {
  @apply flex flex-col justify-center items-center;
  @screen lg {
    @apply flex-row items-baseline;
  }
}

.card {
  @apply flex flex-col items-center text-center w-full;
  margin: 0 10px 20px;
  @screen sm {
    width: 364px;
  }
}

.image {
  @apply mb-4;
}
