@import 'styles/_variables.scss';

.container {
  @apply flex justify-between;
}

.heading {
  @apply text-center mb-6;

  @screen lg {
    margin-bottom: 60px;
  }
}

.backButton {
  @apply mb-8;

  @screen lg {
    margin-bottom: 60px;
  }
}

.image {
  @apply mr-5;
  width: 56px;
  height: 79px;
  object-fit: contain;

  @screen md {
    margin-right: 50px;
  }
}

.leftWrapper {
  @apply flex items-start;
  margin-right: 8px;

  @screen md {
    @apply items-center;
    margin-right: 50px;
  }
}

.title {
  @apply uppercase mb-1;
  font-size: 12px;
  font-weight: 900;

  @screen md {
    font-size: 13px;
  }
}

.rightWrapper {
  @apply flex flex-col;

  @screen md {
    @apply flex-row items-center;
  }
}

.input {
  @apply mb-5;
  width: 64px;
  padding: 10px;
  border: 2px solid $color-gray-light;
  border-radius: 50px;
  font-size: 18px;
  text-align: center;

  @screen md {
    @apply mb-0;
    margin-right: 50px;
    width: 73px;
  }
}

.removeButton {
  @apply flex items-center;
}

.removeButtonText {
  margin-right: 10px;
}

.crossIcon {
  width: 18px;
  height: 18px;

  @screen md {
    width: 24px;
    height: 24px;
  }
}
