@import 'styles/_variables.scss';

.accordion {
  @apply pb-5;
  box-shadow: 0px 10px 15px -12px #cbcbcf;

  @screen lg {
    @apply pb-0;
    box-shadow: none;
  }
}

.headingWrapper {
  @apply w-full flex justify-between items-start;
}

.heading {
  @apply mb-5 uppercase text-left;
}

.arrowIcon {
  @apply mt-2 shrink-0;
  transition: transform 0.15s linear;

  &Rotated {
    transform: rotate(180deg);
  }
}

.paragraphWrapper {
  @apply flex items-center whitespace-nowrap;

  @screen lg {
    @apply mb-5;
  }
}

.paragraph {
  @apply text-xs;
  line-height: 1.5;
  margin-left: 10px;
}

.listWrapper {
  @apply hidden absolute bg-white w-full left-0 px-5 pt-5;
  box-shadow: 0px 10px 15px -12px #cbcbcf;

  @screen lg {
    @apply block static px-0 pt-0;
    box-shadow: none;
  }

  &Open {
    @apply block;
  }
}

.list {
}

.line {
  @apply hidden w-full absolute left-0;
  content: '';
  height: 1px;
  border-bottom: 1px solid $color-blue-light;

  @screen lg {
    @apply block;
  }
}
