.wrapper {
  border-width: 1px;
  padding: 10px;
  border-radius: 10px;
  @apply flex flex-col items-center bg-white border-blue-light border-solid w-full;
  @screen lg {
    @apply flex-row;
  }
}

.image {
  width: 140px;
  height: 140px;
  margin-bottom: 10px;
  @screen lg {
    margin-bottom: 0;
    min-width: 255px;
    min-height: 255px;
    width: 255px;
    height: 255px;
  }
}

.content {
  @apply flex flex-col items-center;
  @screen lg {
    @apply items-start;
    @apply ml-5;
  }
}

.title,
.desc {
  @apply text-center;
  margin-bottom: 10px;
  @screen lg {
    @apply text-left;
    margin-bottom: 25px;
  }
}

.btn {
  @apply uppercase w-fit;
}
