.wrapper {
  @apply bg-blue;
}

.container {
  @apply section-size mx-auto;
  padding-left: 0;
  padding-right: 0;
  @screen sm {
    margin-bottom: 115px;
  }
}

.header {
  @apply uppercase text-center;
}

.desc {
  @apply text-white text-center mt-5;
  font-size: 16px;
  line-height: 1.5;
  @screen sm {
    font-size: 18px;
  }
}

.cards {
  @apply flex flex-wrap mt-10 justify-center;
  @screen sm {
    margin-bottom: -187px;
  }
}

.row {
  @apply flex flex-wrap;
}

.card {
  @apply flex flex-col bg-white w-full;

  min-height: 282px;
  border-radius: 10px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.25);
  margin: 0 10px 20px;
  padding: 20px 10px;

  @screen sm {
    width: 262px;
    min-height: 384px;
    padding: 30px 10px;
  }
}

.cardHeader {
  @apply uppercase text-center mb-5;
  @screen lg {
    margin-bottom: 10px;
  }
}

.cardHeaderLowercase {
  @apply lowercase;
}

.cardText {
  @apply text-center mb-5;
  font-size: 16px;
  line-height: 1.5;
  margin-left: 3px;
  margin-right: 3px;
  @screen sm {
    font-size: 18px;
  }
}

.cardBtn {
  @apply mt-auto uppercase justify-center w-fit mx-auto;
  @screen sm {
    @apply w-auto;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.fakeBreak {
  @apply hidden;
  @media only screen and (min-width: 416px) {
    @apply block;
  }
  @media only screen and (min-width: 460px) {
    @apply hidden;
  }
  @screen lg {
    @apply block;
  }
}
