.line {
  @apply block absolute bottom-2 bg-orange;
  left: 50%;
  transform: translate(-50%, 0);
  width: 60%;
  content: '';
  height: 2px;
  transition: all 0.15s linear;
}

.filters {
  @apply relative w-full z-10;
  border-radius: 15px;
}

.list {
  @apply w-full flex;
  z-index: -1;
  list-style: none;
  border-radius: 15px;
}

.listItemButton {
  @apply w-full text-left;
  padding: 10px;
}

.listItem {
  @apply bg-white cursor-pointer uppercase relative;
  font-size: 18px;

  &:after {
    @extend .line;
    width: 0%;
    transform: translate(-50%, 0);
  }

  &:hover {
    &:after {
      width: 60%;
      transform: translate(-50%, 0);
    }
  }

  &Active {
    .listItemButton {
      font-weight: 700;
    }

    &:after {
      width: 60%;
      transform: translate(-50%, 0);
    }
  }
}
