.wrapper {
  @apply bg-blue-light;
}

.container {
  @apply section-size mx-auto text-center bg-blue-light;
}

.header {
  @apply uppercase mx-auto mb-8;
  max-width: 590px;
}

.headerData {
  @apply mb-5 uppercase;

  @screen lg {
    @apply mb-10;
  }
}

.calculator {
  @apply bg-white;
  padding: 20px 15px;
  border-radius: 15px;
  box-shadow: 20px 20px 74px rgba(0, 0, 0, 0.25);

  @screen lg {
    padding: 40px 56px;
  }
}

.form {
  @apply grid;
  grid-auto-flow: row;

  @screen md {
    grid-template-columns: repeat(2, auto);
    column-gap: 27px;
  }

  @screen xl {
    grid-template-columns: repeat(3, auto);
    column-gap: 60px;
  }
}

.input {
  @apply mb-5 self-end;

  @screen lg {
    @apply mb-10;
  }
}

.button {
  @apply mx-auto;
  grid-column-start: span 1;

  @screen md {
    grid-column-start: span 2;
  }

  @screen lg {
    grid-column-start: span 3;
  }
}

.requiredText {
  @apply mb-10 -mt-2 text-left lg:-mt-4;
}

.heading1 {
  @apply mb-5 uppercase;
  grid-area: heading-1;
}

.heading2 {
  @apply mb-5 mt-5 uppercase lg:mt-0;
  grid-area: heading-2;
}

.results {
  @apply text-base grid text-center mt-5 xl:mt-10;
  grid-template-columns: 1fr;
  grid-template-areas:
    'heading-1'
    'content-1'
    'heading-2'
    'content-2';

  @screen lg {
    @apply text-left;
    grid-template-columns: 6fr 4fr;
    column-gap: 27px;
    grid-template-areas:
      'heading-1 heading-2'
      'real ideal';
  }
}

.real {
  @apply text-left;
  grid-area: 'real';
  padding: 20px 10px;
  border-radius: 20px;
  background-color: #f5fafd;
}

.ideal {
  @apply text-left bg-blue text-white;
  grid-area: 'ideal';
  padding: 20px 10px;
  border-radius: 20px;
}

.rowWithOneItem {
  @apply relative;

  &:not(:last-child) {
    @apply mb-8;
    &::after {
      @apply absolute w-full block -bottom-4;
      content: '';
      height: 1px;
      background-color: #b6bbbe;
    }
  }
}

.row {
  @apply grid relative;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;

  &:not(:last-child) {
    @apply mb-8;
    &::after {
      @apply absolute w-full block -bottom-4;
      content: '';
      height: 1px;
      background-color: #b6bbbe;
    }
  }

  @screen md {
    grid-template-columns: repeat(2, 1fr);
  }

  & > *:nth-child(2n) {
    margin-top: 5px;

    @screen md {
      @apply text-right mt-0;
    }
  }
}
