.container {
  @apply bg-blue-light;
  padding: 80px 0;
}

.content {
  @apply mx-auto;
  max-width: 750px;
  padding: 0 20px;
  @screen lg {
    @apply p-0;
  }
}

.header {
  @apply text-center uppercase mb-6;
}
