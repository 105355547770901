.wrapper {
  &Disabled {
    @apply pointer-events-none;
  }
}

.dropArea,
.imagesArea {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, 105px);
  grid-auto-rows: 1fr;
  justify-content: center;

  @screen lg {
    column-gap: 20px;
    grid-template-columns: repeat(auto-fit, 209px);
  }
}

.imagesArea {
  @apply my-10;
}
