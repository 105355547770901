.newsletter {
  @apply flex flex-col w-full bg-blue justify-center items-center text-center;
  padding: 22px;
  @screen lg {
    padding: 33px;
  }
  @screen xl {
    @apply flex-row;
  }
}

.btn {
  @apply uppercase mt-5;
  @screen xl {
    @apply mt-0;
    margin-left: 36px;
  }
}

.modalBtn {
  @apply mx-auto uppercase w-fit;
}

.modalContent {
  padding: 0 30px;
}

.input {
  @apply mb-5 text-left;
}

.header {
  margin-bottom: 10px;
  padding: 0 5px;
  @screen md {
    @apply mr-16;
  }
}

.tip {
  @apply text-left mb-5;
}
