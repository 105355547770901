.content {
  @apply section-size mx-auto;
}

.heading {
  @apply text-center mb-6 lg:mb-10;
}

.actionBar {
  @apply flex justify-between items-center mb-7 lg:mb-20;
}

.shop {
  @apply grid mb-10;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 20px;

  @screen md {
    @apply mb-16;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 60px;
  }

  @screen lg {
    grid-template-columns: repeat(4, 1fr);
  }
}

.pagination {
  @apply mb-5;

  @screen lg {
    margin-bottom: -20px;
  }
}

.scrollToTop {
  @apply hidden;

  @screen md {
    @apply flex;
  }
}
