.container {
  @apply bg-orange-500 mx-auto w-full md:my-0 flex flex-col justify-center text-center;
}

.content {
  @apply mx-auto text-white py-3 section-size w-full;
  @screen xl {
    padding-left: 120px;
    padding-right: 120px;
  }
}

.header {
  @apply uppercase font-extrabold mb-8 text-center text-white;
  @screen lg {
    @apply mb-12;
  }
}

.stats {
  @apply flex flex-col justify-center;
  @screen xl {
    @apply flex-row justify-between items-start;
  }
}

.entry {
  @apply flex flex-col justify-center text-center;
  @screen xl {
    width: 295px;
  }
}

.entryStat {
  @apply uppercase font-black;
  font-size: 50px;
  line-height: 70px;
  @screen md {
    font-size: 70px;
  }
}

.entryText {
  @apply mb-4;
  line-height: 1.5;
  @screen md {
    font-size: 23px;
    @apply m-0;
  }
}

.btn {
  @apply mx-auto w-fit mt-5 uppercase;
  @screen lg {
    @apply mt-10;
  }
}
