.container {
  padding: 60px 60px;
}

.componentBoxTitle {
  @apply text-blue-pickled text-base py-4;
}

.componentBox {
  margin: 30px 10px;
  padding: 30px 30px;
  border: 1px solid gray;
}
