.box {
  @apply flex flex-col justify-center;
  padding: 63px 20px 25px;
  min-height: 453px;
  @screen lg {
    padding: 62px 121px 50px;
  }
}

.question {
  @apply text-center mb-8;
}

.buttons {
  @apply flex mt-10 justify-center;
}

.btn {
  @apply uppercase w-fit mx-5;
}

.answer {
  @apply text-center;
  margin-bottom: 10px;
}

.result {
  @apply text-center;
  min-height: 24px;
  @screen lg {
    min-height: 27px;
  }
}

.points {
  @apply font-bold;
}

.button {
  @apply w-fit mx-auto mt-8 uppercase;
}
