.title {
  @apply flex mb-5;
}

.logo {
  @apply ml-auto;
  width: 99px;
  height: 102px;
  @screen lg {
    width: 139px;
    height: 143px;
  }
}

.header {
  @apply mb-5;
  padding-right: 10px;
  width: calc(100% - 99px);
  @screen lg {
    @apply pr-5;
    width: calc(100% - 139px);
  }
}

.desc {
  @apply mb-10;
}

.iconRow {
  @apply flex flex-col items-center mb-5;
  @screen lg {
    @apply flex-row;
  }
}

.iconText {
  @apply italic text-center;
  @screen lg {
    @apply text-left;
  }
}

.icon {
  @apply mb-5;
  width: 140px;
  height: 140px;
  min-width: 140px;
  min-height: 140px;
  @screen lg {
    @apply mr-5 mb-0;
  }
}

.tip {
  @apply mb-10 italic;
  margin-top: 10px;
  font-size: 12px;
  line-height: 1.5;
}

.text {
  @apply mb-10;
}

.sectionHeader {
  @apply mb-5;
}
