.container {
  @apply mx-auto section-size w-full my-5 md:my-0 flex flex-col justify-center text-center;
  padding-left: 0;
  padding-right: 0;
}

.header {
  @apply text-blue-pickled font-extrabold uppercase;
}

.logos {
  @apply flex flex-wrap justify-center;
}

.row {
  @apply flex w-fit;
}

.logo {
  @apply flex justify-center items-center;
  height: 80px;
  margin: 40px 5px 0;
  @screen lg {
    margin: 40px 20px 0;
  }
}
