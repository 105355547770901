@import 'styles/_variables.scss';

.container {
  @apply section-size mx-auto text-center;
  color: $color-blue-pickled;
  padding-top: 40px !important;
  padding-bottom: 40px !important;

  @screen lg {
    padding-top: 69px !important;
    padding-bottom: 69px !important;
  }
}

.logo {
  @apply mx-auto mb-5;
  width: 137px;

  @screen lg {
    @apply mb-10;
    width: 259px;
  }
}

.header {
  @apply text-center mb-5;
}

.desc {
  @apply mb-5;
}

.desc2 {
  @apply mb-5 lg:mb-10 mx-auto;
  max-width: 620px;
}

.browsers {
  @apply flex flex-col mb-5;

  @screen lg {
    @apply flex-row justify-center mb-20;
  }

  & > *:nth-child(1) {
    @apply mb-5;

    @screen lg {
      margin-right: 78px;
      @apply mb-0;
    }
  }

  & > *:nth-child(2) {
    @screen lg {
      margin-left: 78px;
    }
  }
}

.browser {
  @apply flex flex-col items-center;
}

.browserIcon {
  @apply mb-4;
}

.title {
  @apply text-lg mb-5;
  font-weight: 700;
}

.link {
  @apply text-center flex justify-center items-center;
  background-color: $color-orange;
  color: white;
  font-weight: 700;
  width: 209px;
  height: 46px;
}

.gotoLink {
  color: $color-blue;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
}
