.container {
  @apply flex mb-5;
}

.dashedBoxWrapper {
  @apply h-full bg-blue-water;
  padding: 10px;
  border-radius: 10px;
}

.dashedBox {
  @apply bg-blue-water h-full;
  border: 2px dashed white;
  border-radius: 10px;
}

.dropzone {
  @apply mr-2;
  border-radius: 10px;
  width: 158px;
  min-width: 158px;
  height: 52px;

  @media (min-width: 375px) {
    @apply mr-5;
  }

  @screen md {
    margin-right: 10px;
  }

  @screen lg {
    width: 160px;
    min-width: 160px;
  }

  &WithImg {
    background-color: transparent;
  }

  &Over {
    @apply bg-blue opacity-50;

    .dashedBoxWrapper {
      @apply bg-blue;
    }

    .dashedBox {
      @apply bg-blue;
    }
  }
}

.descriptionWrapper {
  @apply flex items-center;
}

.description {
  @apply flex justify-start items-center flex-grow relative pl-3 h-fit;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;

  &::before {
    @apply absolute top-0 left-0;
    content: '-';
  }
}
