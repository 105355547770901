.container {
  @apply section-size mx-auto;
}

.topSection {
  @apply flex pb-3 flex-col;
  @screen xl {
    @apply flex-row pb-10;
  }
}

.topContent {
  @apply flex-1;
  flex-basis: 61.5%;
  @screen xl {
    @apply mr-4;
  }
}

.image {
  @apply flex-1 hidden;
  flex-basis: 38.5%;
  @screen xl {
    @apply block ml-4;
  }
  img {
    @apply ml-auto;
  }
}

.imageTablet {
  img {
    @apply mx-auto mb-5;
  }
  @screen xl {
    @apply hidden;
  }
}

.header {
  @apply mb-7 uppercase;
}

.desc {
  @apply mb-7;
  @screen xl {
    @apply mr-24;
  }
}

.divider {
  @apply text-center my-10;
}

.bottomSection {
  @apply flex flex-col justify-center items-center;
  @screen lg {
    @apply flex-row items-baseline;
  }
}

.bottomContent {
  @apply flex flex-col items-center text-center w-full;
  margin: 0 10px 20px;
  @screen sm {
    width: 350px;
  }
}

.icon {
  @apply mb-5;
}
