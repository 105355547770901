.dropElements {
  @apply grid mb-10;
  row-gap: 10px;
  column-gap: 15px;
  grid-template-columns: repeat(2, minmax(100px, 160px));
  grid-auto-rows: 1fr;

  @media (min-width: 375px) {
    grid-template-columns: repeat(auto-fill, 160px);
  }

  @screen lg {
    column-gap: 9px;
  }
}

.dropElement {
  @apply flex;

  &Disabled {
    @apply pointer-events-none;
  }
}
