@import 'styles/_variables.scss';

.radio {
  display: none;
}

.radioLabel {
  @apply text-lg text-blue-pickled w-full block cursor-pointer;
  min-height: 47px;
  padding: 10px;
  border: 1px solid $color-blue-light;
  border-radius: 10px;

  &Checked {
    @apply text-blue bg-blue-light;
  }
}
