.container {
  @apply section-size mx-auto;
  padding-left: 0;
  padding-right: 0;
}

.header {
  @apply text-center uppercase;
}

.logos {
  @apply flex flex-wrap justify-center;
}

.logo {
  @apply border-gray-light border-solid flex justify-center items-center;
  height: 80px;
  margin: 40px 5px 0;
  @screen lg {
    margin: 40px 20px 0;
  }
}
