.footer {
  @apply bg-blue-dark text-white text-center;
  padding: 40px 43px 40px 43px;

  @screen lg {
    padding: 40px;
  }
}

.content {
  @apply mx-auto;
  max-width: 1132px;
}

.title {
  @apply mb-3 font-bold;

  @screen lg {
    @apply text-base;
  }
}

.icons {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 13px;
  justify-content: center;
}

.icon {
  @screen lg {
    @apply w-8 h-8;
  }
}

.iconWhite {
  path {
    &:first-of-type {
      @apply fill-blue-pickled;
    }
    &:not(:first-of-type) {
      @apply fill-white;
    }
  }
}

.iconBlueLight {
  path {
    &:first-of-type {
      @apply fill-blue-pickled;
    }
    &:not(:first-of-type) {
      @apply fill-blue-light;
    }
  }
}

.description {
  font-size: 12px;
  line-height: 1.5;
}

.policysWrapper {
  @apply flex flex-col;

  @screen md {
    @apply flex-row justify-center;
  }
}

.policy {
  &:hover {
    text-decoration: underline;
  }
}

.policyWrapper {
  @screen md {
    &:not(:last-child) {
      &:after {
        @apply inline-block mx-3;
        content: '|';
      }
    }
  }
}
