@import 'styles/variables.scss';

.container {
  @apply section-size mx-auto;
}

.wrapper {
  @apply flex flex-col;
  @screen sm {
    @apply flex-row;
  }
}

.header {
  @apply uppercase mb-5;
  @screen lg {
    @apply mb-10;
  }
}

.desc {
  min-height: 24px;
  @screen lg {
    min-height: 27px;
  }
}

.btn {
  @apply uppercase mt-5;
  @screen lg {
    @apply mt-10;
  }
}

.left {
  @apply flex-1;
  @screen sm {
    @apply mr-5;
    flex-basis: 65%;
  }
}

.right {
  @apply flex flex-1 border-blue-light border-solid p-5 h-fit mt-5;
  border-width: 1px;
  border-radius: 10px;
  @screen sm {
    @apply ml-5 mt-0 flex-col;
    flex-basis: 35%;
    min-width: 160px;
  }
  @screen lg {
    padding: 55px 30px;
    min-width: 364px;
    @apply flex-row;
  }
}

.circle {
  width: 115px;
  height: 115px;
  margin-top: 10px;
  @screen sm {
    @apply mb-5;
  }
  @screen lg {
    @apply mb-0;
  }
}

.text {
  font-size: 12px;
  line-height: 1.5;
}

.icon {
  @apply mr-3;
}

.progress {
  @apply flex-1 flex flex-col justify-center items-center;
}

.details {
  @apply flex-1 ml-10;
  @screen sm {
    margin-left: 0;
  }
  @screen lg {
    margin-left: 65px;
  }
}

.row {
  @apply flex mt-3;
}

.certWrapper {
  @apply mt-5;
  @screen lg {
    @apply mt-10;
  }
}
