.container {
  @apply section-size mx-auto flex flex-col;
  @screen lg {
    @apply flex-row;
  }
}

.form {
  @apply flex-1 order-2 mt-10;
  @screen lg {
    @apply mt-0 order-none;
    margin-right: 10px;
  }
}

.map {
  @apply flex-1 order-1;
  @screen lg {
    @apply order-none;
    margin-left: 10px;
  }
}
