.container {
  @apply section-size mx-auto;
}

.header {
  @apply mb-10;
  @screen lg {
    @apply text-center;
  }
}

.subHeader {
  @apply mb-5 text-center;
  @screen lg {
    @apply text-left;
  }
}

.inputRow {
  @apply flex w-full mb-5 flex-col;
  @screen lg {
    @apply flex-row;
  }
}

.input {
  @apply mb-5;
}

.inputInRow {
  @apply flex-1;

  &:first-of-type {
    @apply mr-0 mb-5;
    @screen lg {
      @apply mb-0;
      margin-right: 10px;
    }
  }
  &:last-of-type {
    margin-left: 0;
    @screen lg {
      margin-left: 10px;
    }
  }
}

.dynamicInput {
  @apply relative;
}

.close {
  @apply absolute cursor-pointer w-4 h-4;
  right: 10px;
  bottom: 10px;
}

.closeErr {
  bottom: 30px;
}

.btnAdd {
  @apply uppercase mx-auto mb-10;
  @screen lg {
    @apply mt-10 mb-20;
  }
}

.btnDownload {
  @apply uppercase mx-auto;
}
