.container {
  @apply section-size mx-auto;
}

.header {
  @apply mb-5 text-center;
}

.desc {
  @apply mb-5 text-center;
  @screen lg {
    @apply mb-10;
  }
}

.articles {
  @apply flex flex-col;
}

.article {
  &:not(&:last-of-type) {
    @apply mb-5;
    @screen lg {
      @apply mb-10;
    }
  }
}
