.image-gallery {
  .image-gallery-content.fullscreen {
    .image-gallery-slide-wrapper {
      .image-gallery-slides {
        .image-gallery-slide {
          .image-gallery-image {
            // display: block;
            height: calc(100vh - 2px);
            max-height: calc(100vh - 2px);
          }
        }
      }
    }
  }
}
