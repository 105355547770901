.certificate {
  @apply p-5 border-green border-2 border-solid text-green w-full flex items-center flex-wrap;
  box-shadow: 13.06px 13.06px 48.34px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  @screen lg {
    box-shadow: 20px 20px 74px rgba(0, 0, 0, 0.25);
    @apply px-10 py-8;
  }
}

.text {
  @apply uppercase mr-auto my-2 pr-10;
}

.btn {
  @apply bg-green uppercase my-2;
}
