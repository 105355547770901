@import 'styles/_variables.scss';

.container {
  @apply fixed left-0 bottom-0;
  background-color: $color-blue-pickled;
  z-index: 10010;
  width: 100vw;
}

.content {
  @apply section-size mx-auto flex flex-col items-center text-base text-center;
  color: white;
  padding-top: 20px !important;
  padding-bottom: 20px !important;

  @screen md {
    @apply flex-row text-left;
  }
}

.text {
  @apply w-full;

  @screen lg {
    @apply mr-12;
  }
}

.policy {
  &:hover {
    text-decoration: underline;
  }
}

.button {
  @apply mt-8;

  @screen md {
    @apply mt-0;
  }
}
