@import 'styles/_variables.scss';

.switch {
  @apply flex w-fit mx-auto cursor-pointer;
  border-radius: 50px;
  box-shadow: inset 0px 0px 0px 1px $color-blue-dirty;
}

.checkbox {
  @apply w-0 h-0 opacity-0 hidden;
}

.left,
.right {
  @apply px-5 font-bold text-center flex items-center;
  border-radius: 50px;
  padding: 11px 20px;
  font-size: 14px;
  line-height: 1.5;
}

.left {
  @apply bg-orange text-white;
}

.right {
  @apply bg-transparent text-blue-dirty;
}

.switchChecked {
  .left {
    @apply bg-transparent text-blue-dirty;
  }
  .right {
    @apply bg-orange text-white;
  }
}
