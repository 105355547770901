.list {
  @apply list-disc pl-7 mb-10;
}

.icons {
  @apply flex mb-10 flex-col justify-center items-center;
  @screen md {
    @apply flex-row items-start;
  }
}

.iconWrapper {
  @apply flex flex-col text-center items-center;
  flex-basis: 1/3;
  max-width: 210px;

  @screen md {
    &:not(&:last-of-type) {
      @apply mr-5;
    }
  }
}

.iconText {
  @apply my-5;
  @screen md {
    @apply mb-0;
    margin-top: 10px;
  }
}

.whenToWash {
  @apply flex mt-10 flex-col items-center;
  @screen sm {
    @apply flex-row items-start;
  }
}

.whenToWashLeft {
  @apply flex flex-col;
  @screen sm {
    @apply mr-5;
  }
}

.icon {
  min-width: 210px;
  width: 210px;
}

.difficile {
  @apply flex;
}

.difficileRight {
  @apply flex flex-col;
  @screen sm {
    @apply ml-5;
  }
}
