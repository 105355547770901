.layout {
  height: 100vh;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  @apply relative w-full grow;
}
