.wrapper {
  @apply mb-5 grid;
  grid-template-columns: 1fr;
  row-gap: 40px;

  @screen lg {
    grid-template-columns: 160px auto;
    column-gap: 20px;
  }

  @screen xl {
    column-gap: 40px;
  }

  &Disabled {
    @apply pointer-events-none;
  }
}

.imagesArea {
  display: grid;
  grid-auto-flow: row;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(2, minmax(120px, 158px));
  grid-auto-rows: 1fr;

  @media (min-width: 375px) {
    column-gap: 19px;
    row-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(auto, 158px));
  }

  @screen lg {
    row-gap: 0;
    column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(auto, 160px));
  }
}

.dropArea {
  @apply grid;
  grid-auto-flow: row;
  grid-auto-rows: 1fr;
}
