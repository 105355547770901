.wrapper {
  @apply bg-blue;
}

.container {
  @apply section-size mx-auto;
}

.header {
  @apply text-center uppercase;
  margin-bottom: 20px;
}

.desc {
  @apply mb-5 text-center;
  @screen lg {
    margin-bottom: 30px;
  }
}

.section {
  @apply flex mb-10 flex-col;
  @screen lg {
    @apply flex-row;
  }
}

.sectionTitle {
  @apply block mb-5;
}

.videoWrapperLeft {
  @apply flex-1 mb-5;
  @screen lg {
    @apply mr-3 mb-0;
  }
}

.videoWrapperRight {
  @apply flex-1 mb-5 order-1;
  @screen lg {
    @apply ml-3 mb-0 order-none;
  }
}

.contentLeft {
  @apply flex-1 flex flex-col justify-center order-2 text-center;
  @screen lg {
    @apply mr-3 text-left order-none;
  }
}

.contentRight {
  @apply flex-1 flex flex-col justify-center text-center;
  @screen lg {
    @apply ml-3 text-left;
  }
}
