@import 'styles/_variables.scss';

.accordion {
  @apply mb-5;

  @screen lg {
    @apply mb-0;
  }

  &Open {
    @apply mb-0;
  }
}

.headingWrapper {
  @apply w-full flex justify-between items-start;

  @screen lg {
    @apply my-5;
  }
}

.paragraphWrapper {
  @apply flex items-center mb-5 whitespace-nowrap;

  @screen lg {
    @apply ml-auto pl-4 pt-2;
    margin-right: 120px;
  }
}

.list {
  @apply hidden mt-5;

  &Open {
    @apply block;
  }
}

.line {
  @apply block w-full absolute left-0 mt-5;
  content: '';
  height: 1px;
  border-bottom: 1px solid $color-blue-light;

  @screen lg {
    @apply mt-0;
  }
}
