.wrapper {
  @apply bg-blue text-white;
}

.container {
  @apply section-size mx-auto flex flex-col;
  @screen lg {
    @apply flex-row;
  }
}

.header {
  @screen lg {
    @apply mb-10;
  }
}

.left {
  @apply flex-1 flex flex-col order-2 mt-5 w-fit;
  @screen lg {
    @apply order-none mt-0;
    margin-right: 10px;
  }
}

.image {
  @apply w-fit;
  border-radius: 10px;
}

.right {
  @apply flex-1 order-1 text-center;

  @screen lg {
    @apply order-none text-left;
    margin-left: 10px;
  }
}

.desc {
  @apply my-5;
}

.btn {
  @apply uppercase mx-auto w-fit;
  @screen lg {
    @apply mt-10 mx-0;
  }
  white-space: normal;
  height: auto;
}
