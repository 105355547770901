.paragraphWhite {
  @apply text-white;
}

.paragraph {
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-line;
  @screen lg {
    font-size: 18px;
  }
}
