.wrapper {
  @apply bg-blue-light;
}

.container {
  @apply section-size mx-auto flex flex-col-reverse;
  @screen lg {
    @apply flex-row;
  }
}

.header {
  @apply uppercase;
}

.content {
  @apply flex-1;
  @screen lg {
    @apply mr-4;
  }
}

.image {
  @apply flex-1 mx-auto mb-5;
  @screen lg {
    @apply ml-4;
  }
}

.btn {
  @apply uppercase w-fit;
  @screen lg {
    margin-bottom: 60px;
  }
}

.desc {
  @apply my-5;
  @screen lg {
    @apply my-10;
  }
}

.socialsText {
  @apply font-black mb-5 hidden;
  @screen lg {
    @apply block;
  }
}

.icons {
  @apply hidden justify-start;
  grid-template-columns: repeat(4, auto);
  grid-gap: 20px;
  @screen lg {
    @apply grid;
  }
}
