.heading {
  @apply text-blue-pickled uppercase whitespace-pre-wrap;
}

.headingWhite {
  @apply text-white;
}

.h1 {
  @apply font-extrabold;
  font-size: 30px;
  line-height: 32px;
  @screen lg {
    @apply font-black;
    font-size: 55px;
    line-height: 1.22;
  }
}

.h2 {
  @apply font-extrabold;
  font-size: 30px;
  line-height: 32px;
  @screen lg {
    font-size: 35px;
    line-height: 1.5;
  }
}

.h3 {
  @apply font-bold;
  font-size: 18px;
  line-height: 1.5;
  @screen lg {
    font-size: 22px;
  }
}
