.icons {
  @apply grid;
  align-items: flex-start !important;
  grid-template-columns: repeat(1, auto);
  place-items: center;
  @screen md {
    column-gap: 20px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @screen 2xl {
    column-gap: 56px;
  }
}

.icon {
  @apply flex flex-col text-center w-full;
  &:not(&:last-of-type) {
    @apply mb-5;
  }
  max-width: 210px;
  @screen md {
    &:not(&:last-of-type) {
      @apply mb-0;
    }
  }
}

.hyphens {
  hyphens: auto;
  overflow-wrap: break-word;
}

.imgWrapper {
  height: 147px;
  img {
    height: 100%;
  }
}

.iconText {
  margin-top: 10px;
}
