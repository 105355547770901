.container {
  @apply flex justify-center text-center text-green text-base;
  color: black;

  @screen lg {
    @apply text-lg;
  }

  a {
    @apply w-full cursor-pointer;
  }
}

.page,
.break {
  @apply text-blue hover:underline mx-1 h-8 w-8 flex justify-center items-center;
  border-radius: 50%;
  color: black;

  @screen sm {
    @apply mx-2;
  }
}

.active {
  @apply bg-blue-onahau text-blue-pickled;
}

.previous,
.next {
  @apply mx-1;
  font-size: 20px;
  line-height: 1.5;
}
