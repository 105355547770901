@import 'styles/_variables.scss';

.container {
  @apply flex;
}

.checkbox {
  @apply appearance-none absolute top-0 left-0;
  margin-top: 4px;
  margin-right: 10px;
  &::after {
    @apply absolute z-10;
    width: 19px;
    height: 19px;
    border-radius: 5px;
    content: '';
    border: 1px solid $color-blue-light;
  }

  &:checked {
    &::before {
      @apply absolute z-10 bg-blue;
      content: '';
      top: 4px;
      left: 4px;
      width: 11px;
      height: 11px;
      border-radius: 4px;
      border: 1px solid $color-blue-light;
    }
  }
}

.checkboxLabel {
  @apply text-base w-full block relative;
  padding-left: 30px;
  padding-top: 3px;
}

.errorBox {
  @apply text-sm text-red;
  margin-top: 5px;
}

.checkboxError {
  &::after {
    border: 1px solid $color-red;
  }
}
