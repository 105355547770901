.carousel {
  @apply border-blue-light border-solid;
  border-width: 1px;
}

.left {
  @apply absolute top-1/2 z-10 cursor-pointer;
  transform: translateY(-50%) rotateY(180deg);
  left: 10px;
}

.right {
  @apply absolute top-1/2 z-10 cursor-pointer;
  transform: translateY(-50%);
  right: 10px;
}

.fullscreen {
  @apply absolute z-10 cursor-pointer;
  right: 10px;
  bottom: 10px;
  width: 22px;
  height: 22px;
  @screen lg {
    width: 32px;
    height: 32px;
  }
}

.index {
  @apply absolute left-1/2 z-10;
  bottom: 20px;
}
