@import 'styles/variables';

.inputWrapper {
  @apply text-left;
}

.input {
  @apply text-base w-full text-blue-pickled bg-white;
  background-color: white;
  height: 35px;
  line-height: 1.5;
  padding: 7px;
  border: 1px solid $color-blue-light;
  border-radius: 4px;

  @screen lg {
    @apply text-lg;
  }

  &:focus {
    @apply outline-blue;
  }
}

.label {
  @apply block text-base text-blue-pickled mb-1;

  @screen lg {
    @apply text-lg;
  }
}

.errorBox {
  @apply text-sm text-red;
}

.adnotation {
  @apply text-sm;
}

.inputWrapperError {
  .input {
    border: 1px solid $color-red;
  }
}
