.container {
  @apply section-size mx-auto;
}

.header {
  @apply uppercase text-center;
}

.desc {
  @apply text-center mt-5 mb-7;
}

.materials {
  @apply flex flex-wrap;
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 50%);
  grid-auto-rows: 1fr;
  grid-gap: 20px 11px;
  @screen lg {
    grid-template-columns: repeat(3, 33.3%);
    grid-gap: 70px 17px;
  }
  @screen xl {
    grid-template-columns: repeat(4, 25%);
  }
}

.material {
  @apply flex flex-col;

  img {
    @apply justify-start;
  }
}

.text {
  @apply text-center;
  margin: 10px 0;
  @screen lg {
    @apply text-left;
    margin: 24px 0;
  }
}

.btn {
  @apply uppercase mx-auto mt-auto;
  @screen lg {
    padding: 0 55px;
  }
}
