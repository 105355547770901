@import 'styles/_variables.scss';

.imageWrapper {
  @apply flex flex-col h-full;
}

.description {
  @apply flex-grow;
  padding: 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  border: 1px solid $color-blue-light;
  border-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropzone {
  border-radius: 10px;

  &Over {
    .description {
      @apply opacity-50 bg-blue;
    }
  }
}
